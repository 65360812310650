import { APIPath } from "../utility/constant";
import { BaseService } from "./baseService";

class Signaure {
    generate = (data) => {
        return BaseService.post(`${APIPath.generateSignature}`, data);
    };
}

const SignatureService = new Signaure();
Object.freeze(SignatureService);
export { SignatureService };
