import React, { useEffect, useState } from "react";
import { TableCompWrapper, TableDiv } from "./element";
import { Container } from "react-bootstrap";
import { Space, Table, Tag } from "antd";
import { Erc20Service } from "../../../service/erc20Service";
import { CommonUtility } from "../../../utility/common";
import environment from "../../../environment";

function TableComp({ getTxs, data }) {
  const openTx = (transaction_hash) => {
    window.open(
      `${
        environment.EXPLORER_URL[environment.DEFAULT_CHAIN]
      }/tx/${transaction_hash}`
    );
  };
  const columns = [
    {
      title: "Txn Hash",
      render: (item) => (
        <a onClick={() => openTx(item?.tx_hash)}>
          {CommonUtility.addressConvertor(item?.tx_hash)}
        </a>
      ),
    },
    {
      title: "Amount",
      render: (item) => (
        <a>{CommonUtility.removeTrailingZeroes(item?.amount / 10 ** 18)}</a>
      ),
    },
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
    },
    {
      title: "Age",
      render: (item) => <a>{CommonUtility.unixToDate(item?.created_at)}</a>,
    },
    {
      title: "From",
      render: (item) => (
        <a>{CommonUtility.addressConvertor(item?.from_address)}</a>
      ),
    },
    {
      title: "To",
      render: (item) => (
        <a>{CommonUtility.addressConvertor(item?.to_address)}</a>
      ),
    },
  ];

  useEffect(() => {
    getTxs();
  }, []);
  return (
    <TableCompWrapper>
      <Container>
        <TableDiv>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ y: 700 }}
          />
        </TableDiv>
      </Container>
    </TableCompWrapper>
  );
}

export default TableComp;
