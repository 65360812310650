import React, { useState } from "react";
import { ConnectBtn, NavbarWrapper } from "./elements";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { logo } from "../../../assets";
import { Image } from "react-bootstrap";
import { Modal } from "antd";
import ConnectModal from "../../common/connectModal";
import { useWalletConnectClient } from "../../../clientContext";

const NavbarComp = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { account } = useWalletConnectClient();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <NavbarWrapper>
      <Modal
        open={isModalOpen}
        centered
        onCancel={handleCancel}
        width={504}
        footer={false}
        closable={false}
      >
        <ConnectModal onCancel={handleCancel} />
      </Modal>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            <Image src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Ecosystem</Nav.Link>
              <Nav.Link href="#home">BIDZ SWAP</Nav.Link>
              <Nav.Link href="#link">Partnership</Nav.Link>
            </Nav>
            {account ? (
              <ConnectBtn>Connected</ConnectBtn>
            ) : (
              <ConnectBtn onClick={showModal}>Connect Wallet</ConnectBtn>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </NavbarWrapper>
  );
};

export default NavbarComp;
