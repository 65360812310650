import styled from "styled-components";

export const FooterWrapper = styled.div`
  margin-top: 4rem;
  background: #292930;
  overflow: hidden;

  .main-row {
    margin-top: 6rem;
  }
`;

export const LeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 991.98px) {
    align-items: center;
  }

  p {
    color: #8a90a2;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 173.333% */
    margin-top: 2rem;

    @media (max-width: 991.98px) {
      margin-top: 1rem;
    }
  }
`;

export const SocialIconsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;

  @media (max-width: 991.98px) {
    margin-top: 1rem;
  }
`;

export const IconHolder = styled.div`
  border-radius: 22px;
  background: #3e3e45;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 2px;
  cursor: pointer;
  transition: 300ms ease-in-out all;

  &:hover {
    scale: 1.1;
    transition: 300ms ease-in-out all;
  }

  svg {
    color: #9e9ea2;
  }
`;

export const MiddleDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  @media (max-width: 991.98px) {
    margin-top: 2rem;
  }
  @media (max-width: 575.98px) {
    justify-content: space-between;
  }
`;

export const MiddleFirstDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h2 {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
  }

  p {
    color: #8a90a2;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 173.333% */
    margin-top: 0.5rem;

    &:hover {
      color: #fff;
    }

    &:first-of-type {
      margin-top: 3.5rem;
      cursor: pointer;

      @media (max-width: 991.98px) {
        margin-top: 2rem;
      }
    }
  }
`;

export const MiddleSecondDiv = styled(MiddleFirstDiv)``;

export const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 991.98px) {
    margin-top: 2rem;
    align-items: center;
  }

  h2 {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
  }

  p {
    color: #8a90a2;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 173.333% */
    margin-top: 2rem;

    @media (max-width: 991.98px) {
      margin-top: 1rem;
    }
  }

  .input-group {
    margin-top: 2rem;

    @media (max-width: 991.98px) {
      margin-top: 1rem;
    }
  }

  .form-control {
    border-radius: 10px;
    background: #313138;
    border: none;
    height: 60px;
    color: #fff;

    &::placeholder {
      color: #96a1b6;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .input-group-text {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #5956e9;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 10px !important;
  }

  svg {
    color: #fff;
    font-size: 20px;
  }
`;

export const BottomDiv = styled.div`
  border-top: 1px solid #3e3e45;
  margin-top: 6rem;

  @media (max-width: 991.98px) {
    margin-top: 2rem;
  }

  .inner-div {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    @media (max-width: 991.98px) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .copy-right-text {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 200% */
    margin: 0;

    @media (max-width: 575.98px) {
      font-size: 12px;
    }
  }

  .right-side {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;

    @media (max-width: 575.98px) {
      flex-direction: column;
      gap: 1rem;
    }

    p {
      color: #fff;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 173.333% */
      margin: 0;
      cursor: pointer;
    }
  }
`;
