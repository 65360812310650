import styled from "styled-components";

export const HeroWrapper = styled.div`
  .container {
    margin-top: 4rem;
  }
`;

export const LeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 991.98px) {
    align-items: flex-start;
  }
`;

export const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 6rem;

  @media (max-width: 991.98px) {
    padding-left: 0;
    margin-top: 2rem;
  }
`;

export const ImgDiv = styled.div`
  text-align: center;

  @media (max-width: 991.98px) {
    text-align: start;
  }
`;

export const InfoDiv = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 991.98px) {
    align-items: flex-start;
  }

  h5 {
    color: #292930;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.65rem; /* 120% */
    width: 300px;
    text-align: start;
  }

  p {
    color: #8a90a2;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem; /* 162.5% */
    margin: 0;
    width: 300px;
    text-align: start;
  }
`;

export const HeaderTitle = styled.h5`
  color: #292930;
  font-size: 4.0625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4.875rem; /* 120% */
`;

export const InputDiv = styled.div`
  width: 100%;

  .main-div {
    margin-top: 3rem;
  }
  h5 {
    color: #8a90a2;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem; /* 185.714% */
    margin-bottom: 0rem;
  }

  .form-control {
    border-radius: 0.625rem;
    border: 1px solid #eff1f6;
    background: #f2f6ff;
    padding: 1rem 0.0625rem 1rem 1.3125rem;
    margin-top: 0.5rem;
    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #8a90a2;
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

export const SlipageDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;

  p {
    color: #2a166a;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    margin: 0;
  }
`;

export const SwapBtn = styled.button`
  height: 60px;
  padding: 0px 50.51px 0px 50.71px;
  flex-shrink: 0;
  color: #292930;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 375% */
  text-transform: capitalize;
  border-radius: 10px;
  background: #ffdc60;
  border: none;
  margin-top: 1rem;
`;
