import environment from "../environment";
import { Protocols } from "./constant";
import { io } from "socket.io-client";

const queryString = require("query-string");

export class CommonUtility {
  static socketIO = () => {
    const ENDPOINT = environment.BACKEND_BASE_URL;
    const socket = io(ENDPOINT);
    return socket;
  };

  static currencyFormat(value, currency) {
    if (Number.isNaN(value || 0)) {
      return value;
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency || "USD",
    }).format(value || 0);
  }
  static currentTime() {
    return Math.floor(Date.now() / 1000);
  }
  static unixToDate(unixTimestamp) {
    console.log("unixTimestamp", unixTimestamp);
    const milliseconds = Number(unixTimestamp) * 1000;
    // Create a new Date object using the milliseconds
    const date = new Date(milliseconds);
    const formattedDate = date.toLocaleString("en-US", {
      month: "short", // Short month name (e.g., "Sep")
      day: "2-digit", // Two-digit day (e.g., "24")
      year: "numeric", // Full year (e.g., "2023")
      hour: "2-digit", // Two-digit hour (e.g., "21")
      minute: "2-digit", // Two-digit minute (e.g., "15")
      second: "2-digit", // Two-digit second (e.g., "48")
    });
    return formattedDate;
  }

  static isNotEmpty(item) {
    return (
      item !== undefined && item !== null && item !== "" && item.length !== 0
    );
  }

  static truncateString(text, ellipsisString) {
    return (text || "").length > ellipsisString
      ? `${text.substring(0, ellipsisString)}...`
      : text;
  }

  static numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static objectToParams(obj) {
    const str = queryString.stringify(obj);
    return str;
  }

  static toTitleCase(phrase) {
    return phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  static timeoutPromise(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static roundNumber(num, decimals = 6) {
    const t = 10 ** decimals;
    let result = Math.round((num + Number.EPSILON) * t) / t;
    if (num < 0) {
      result *= -1;
    }
    return result;
  }

  static decimalConverter(number, exponent) {
    const decimals = (number * 10 ** exponent).toFixed(0).toString();
    return decimals;
  }

  static addressConvertor(address) {
    if ((address || "").length < 10) {
      return address || "";
    }
    return `${address.slice(0, 4)}...${address.slice(address.length - 4)}`;
  }

  static symbolConvertor(symbol) {
    switch (symbol) {
      case "Cake-LP":
        return "CTZN/BUSD Cake-LP";
      case "UNI-V2":
        return "CTZN/USDC UNI-LP";

      default:
        return symbol;
    }
  }

  static nameConvertor(name, protocol = Protocols.ethereum.name) {
    switch (name) {
      case "Pancake LPs":
        return "CTZN/BUSD Cake-LP";
      case "UNI-V2":
      case "Uniswap V2":
        return "$CTZN / ETH Uni-LP";
      case "Totem Earth Systems":
        return protocol === Protocols.ethereum.name
          ? "CTZN ERC Staking"
          : "CTZN BSC Staking";
      default:
        return name;
    }
  }

  static numFormatter(num) {
    if (!num) return num;
    if (num > 999 && num < 1000000) {
      return `${(num / 1000).toFixed(1)}K`; // convert to K for number from > 1000 < 1 million
    }
    if (num > 1000000) {
      return `${(num / 1000).toFixed(1)}K`; // convert to M for number from > 1 million
    }
    if (num > 1 && num < 900) {
      return num.toFixed(0); // if value < 1000, nothing to do
    }
    if (num < 0) {
      return num.toFixed(4);
    }
    return num;
  }

  static contract(web3, abi, address) {
    return new web3.eth.Contract(abi, address);
  }
  static round(x) {
    if (x) {
      return x.toFixed(5).replace(/\.?0*$/g, "");
    } else {
      return "00.00";
    }
  }
  static shareOnFacebook(url) {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url),
      "Facebook Share",
      "width=600,height=400"
    );
  }

  static shareOnTwitter(url, text) {
    window.open(
      "https://twitter.com/intent/tweet?text=" +
        encodeURIComponent(text) +
        "&url=" +
        encodeURIComponent(url),
      "Twitter Share",
      "width=600,height=400"
    );
  }

  static filterObjectById(array, id) {
    return array.find((obj) => obj.id === id);
  }

  static randomNumber() {
    const length = 10;
    let randomNumber = "";

    for (let i = 0; i < length; i++) {
      randomNumber += Math.floor(Math.random() * 10);
    }

    return randomNumber;
  }
  static viewNft() {
    // window.open(`${environment.OPENSEA_URL}`, "_blank");
  }

  static getRedirectGame(id) {
    switch (id) {
      case 1:
        return "/clumpsy-pepe";
      case 2:
        return "/pep-man";
      default:
        return "";
    }
  }

  static getGameName(id) {
    switch (id) {
      case 1:
        return "Clumpsy Pepe";
      case 2:
        return "Pep Man";
      default:
        return "";
    }
  }

  static rewardList(index) {
    switch (index) {
      case 0:
        return "1st";
      case 1:
        return "2nd";
      case 2:
        return "3rd";
      case 3:
        return "4nd";
      case 4:
        return "5nd";
      default:
        return "";
    }
  }
  static removeTrailingZeroes = (num) => {
    return parseFloat(num.toString().match(/^\d+.\d{2}(?=0*$|\d)/))
      ? num.toString().match(/^\d+.\d{2}(?=0*$|\d)/)[0]
      : Number(num).toFixed(4);
  };
}
