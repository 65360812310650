import React from "react";
import {
  BottomDiv,
  FooterWrapper,
  IconHolder,
  LeftDiv,
  MiddleDiv,
  MiddleFirstDiv,
  MiddleSecondDiv,
  RightDiv,
  SocialIconsDiv,
} from "./element";
import { Col, Container, Image, Row } from "react-bootstrap";
import { logo } from "../../../assets";
import { FaYoutube, FaTwitter, FaFacebookF } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

function FooterComp() {
  return (
    <FooterWrapper>
      <Container>
        <Row className="main-row">
          <Col lg={3}>
            <LeftDiv>
              <Image src={logo} />
              <p>
                BIDZ Coin has a unique approach to the online commerce and
                real-world applications. You can use your BIDZ Coin to buy real
                products or services throughout our ecosystem. Upon launch of
                BIDZ Coin, the team plans to build a bridge between crypto,
                commerce, and real world applications.
              </p>
              <SocialIconsDiv>
                <IconHolder>
                  <FaYoutube />
                </IconHolder>
                <IconHolder>
                  <FaTwitter />
                </IconHolder>
                <IconHolder>
                  <FaFacebookF />
                </IconHolder>
              </SocialIconsDiv>
            </LeftDiv>
          </Col>
          <Col lg={6}>
            <MiddleDiv>
              <MiddleFirstDiv>
                <h2>Useful Links</h2>
                <p>Contact Us</p>
                <p>BIDZ Swap</p>
                <p>BIDZ VPN</p>
                <p>Earn BIDZ </p>
              </MiddleFirstDiv>
              <MiddleSecondDiv>
                <h2>Community</h2>
                <p>Telegram</p>
                <p>Discor</p>
                <p>Twitter</p>
                <p>Facebook </p>
                <p>Medium </p>
              </MiddleSecondDiv>
            </MiddleDiv>
          </Col>
          <Col lg={3}>
            <RightDiv>
              <h2>Newsletter</h2>
              <p>
                Subscribe via email to get the latest updates from BIDZ Coin!
              </p>

              <InputGroup className="input-group">
                <Form.Control placeholder="Enter email" />
                <InputGroup.Text>
                  <FaTelegramPlane />
                </InputGroup.Text>
              </InputGroup>
            </RightDiv>
          </Col>
        </Row>

        <BottomDiv>
          <div className="inner-div">
            <p className="copy-right-text">
              © Copyright ©{new Date().getFullYear()} Bidz Coin. All Rights
              Reserved Copyright
            </p>
            <div className="right-side">
              <p>Terms and conditions</p>
              <p>Privacy policy</p>
              <p>Ecosystem</p>
            </div>
          </div>
        </BottomDiv>
      </Container>
    </FooterWrapper>
  );
}

export default FooterComp;
