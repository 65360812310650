import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Image, Form } from "react-bootstrap";
import { bidzcoinicon, bidzcredit, heroimg } from "../../../assets";
import {
  HeaderTitle,
  HeroWrapper,
  ImgDiv,
  InfoDiv,
  InputDiv,
  LeftDiv,
  RightDiv,
  SlipageDiv,
  SwapBtn,
} from "./elements";
import { useWalletConnectClient } from "../../../clientContext";
import ConnectModal from "../../common/connectModal";
import { Modal } from "antd";
import axios from "axios";
import { tokenAbi } from "../../../contract/erc20Abi";
import environment from "../../../environment";
import { CommonUtility } from "../../../utility/common";
import { SignatureService } from "../../../service/signatureService";
import { depositAbi } from "../../../contract/depositAbi";
import Loading from "../../common/connectModal/lodaer/loading";
import ToastMessage from "../../common/toastMessage";
import { Erc20Service } from "../../../service/erc20Service";
import InfoModal from "../../common/infoModal";

const HeroComp = ({ getTxs }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const [tokenPrice, setTokenPrice] = useState(false);
  const [pricePerToken, setPricePerToken] = useState(false);

  const [credits, setCredits] = useState(0);
  const [creditError, setCreditError] = useState(false);
  const [tokenBalance, setTokenBalance] = useState(false);
  const [loading, setLoading] = useState(false);

  const [swapData, setSwapData] = useState(false);

  const { chain: chainId, account, web3Provider } = useWalletConnectClient();
  console.log("chainId", chainId);
  const { tokenContract, depositContract } = useMemo(() => {
    if (web3Provider) {
      const tokenContract = new web3Provider.eth.Contract(
        tokenAbi,
        environment.TOKEN_ADDRESS[chainId]
      );
      const depositContract = new web3Provider.eth.Contract(
        depositAbi,
        environment.DEPOSIT_ADDRESS[chainId]
      );
      return { tokenContract, depositContract };
    }

    // Return some default values or null when web3Provider is falsy
    return { tokenContract: null, depositContract: null };
  }, [web3Provider, chainId]); // Add web3Provider and chainId as dependencies

  const handleDeposit = async () => {
    try {
      //deposit
      await fetchTokenPrice();
      setLoading(true);
      const amount = web3Provider.utils.toWei(
        (tokenPrice * Number(credits)).toString(),
        "ether"
      );
      console.log("amountamount", amount);
      const allowance = await tokenContract.methods
        .allowance(account, environment.DEPOSIT_ADDRESS[chainId])
        .call();

      if (Number(amount) > Number(allowance)) {
        const totalSupply = await tokenContract.methods.totalSupply().call();
        const approve = await tokenContract.methods
          .approve(environment.DEPOSIT_ADDRESS[chainId], totalSupply)
          .send({ from: account });
      }

      const currentTime = CommonUtility.currentTime();
      const obj = {
        user: account,
        amount: amount,
        chainId: chainId,
        time: currentTime.toString(),
      };
      let signature = await SignatureService.generate(obj);
      const creditInWei = web3Provider.utils.toWei(credits.toString(), "ether");
      const res = await depositContract.methods
        .depositTokens(amount, creditInWei, currentTime, signature?.data)
        .send({ from: account });
      await handleUserBalance();
      await getTxs();
      setLoading(false);
      ToastMessage("Success!", "Transaction Successful", "success");
      setIsInfoModalOpen(true);
      await CommonUtility.timeoutPromise(4500);
      await getSwaps();
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
      ToastMessage("Failed!", "Transaction Failed", "error");
    }
  };

  const handleUserBalance = async () => {
    try {
      let balance = await tokenContract.methods.balanceOf(account).call();
      balance = web3Provider.utils.fromWei(balance.toString(), "ether");

      setTokenBalance(balance);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchTokenPrice = async () => {
    try {
      let res = await axios.get(environment.COIN_GECKO);
      res = res?.data;
      console.log("res", res);
      setPricePerToken(res?.["bidz-coin"]?.usd);
      setTokenPrice(1 / res?.["bidz-coin"]?.usd);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    fetchTokenPrice();
    if (tokenContract) {
      handleUserBalance();
    }
  }, [tokenContract]);

  const handleInputChange = (inputValue, balance, type) => {
    console.log("inputValue", inputValue);
    if (!inputValue || /^-?\d+(\.\d+)?$/.test(inputValue)) {
      if (!inputValue) {
        console.log("inputValue if", inputValue);
        setCreditError("");
        setCredits("");
      } else if (Number(inputValue) <= 0) {
        console.log("inputValue else if 1", inputValue);
        setCredits(inputValue);
        setCreditError("Please enter a number greater than 0.");
      } else if (
        account &&
        Number(tokenPrice * Number(inputValue)) > Number(balance)
      ) {
        console.log("inputValue else if 2", inputValue);
        setCreditError(
          `The amount should be equal to or less than ${type} balance.`
        );
      } else {
        console.log("inputValue else", inputValue);
        setCreditError("");
        setCredits(inputValue);
      }
    } else {
      setCredits(inputValue);
      setCreditError("Please enter a valid number.");
    }
  };
  console.log("tokenBalance", tokenBalance);

  const getSwaps = async () => {
    try {
      let swaps = await Erc20Service.totalSwapAmount();
      swaps = swaps?.data;
      let totalSwaps = await Erc20Service.totalSwaps();
      totalSwaps = totalSwaps?.data;
      setSwapData({
        swaps,
        totalSwaps,
      });
    } catch (error) {
      console.log("getTxs error", error);
    }
  };
  useEffect(() => {
    getSwaps();
  }, []);
  console.log("credits");
  return (
    <HeroWrapper>
      {loading && <Loading />}
      <Container>
        <Row>
          <Col lg={6}>
            <LeftDiv>
              <ImgDiv>
                <Image src={heroimg} />
              </ImgDiv>
              <InfoDiv>
                <h5>BIDZ swaped (Lifetime) :</h5>
                <p>
                  {swapData
                    ? CommonUtility.removeTrailingZeroes(
                        swapData?.swaps / 10 ** 18
                      )
                    : 0}{" "}
                  <b>BIDZ</b>
                </p>
              </InfoDiv>
              <InfoDiv>
                <h5>Number of Transactions</h5>
                <p>{swapData?.totalSwaps || 0}</p>
              </InfoDiv>
              <InfoDiv>
                <h5>Swap's in $ value</h5>
                <p>
                  {" "}
                  <b>$</b>
                  {swapData && pricePerToken
                    ? CommonUtility.removeTrailingZeroes(
                        (swapData?.swaps / 10 ** 18) * pricePerToken
                      )
                    : 0}
                </p>
              </InfoDiv>
            </LeftDiv>
          </Col>
          <Col lg={6}>
            <RightDiv>
              <HeaderTitle>BIDZ Swap</HeaderTitle>
              <InputDiv>
                <div className="main-div">
                  <div className="d-flex gap-2 align-items-center">
                    <Image src={bidzcoinicon} />
                    <h5>BIDZ Credit</h5>
                  </div>
                  <Form.Control
                    value={credits}
                    onChange={(e) =>
                      handleInputChange(e.target.value, tokenBalance, "BIDZ")
                    }
                    placeholder="Enter Credit"
                  />
                </div>

                <div className="main-div">
                  <div className="d-flex gap-2 align-items-center">
                    <Image src={bidzcredit} />
                    <h5>BIDZ Tokens</h5>
                    <h5>
                      User Balance:{" "}
                      {CommonUtility.removeTrailingZeroes(tokenBalance || 0) ||
                        0}
                    </h5>
                  </div>
                  <SlipageDiv>
                    <p>Required Tokens:</p>
                    <p>
                      <b>
                        <span>
                          {credits && !isNaN(tokenPrice) && !isNaN(credits)
                            ? CommonUtility.removeTrailingZeroes(
                                tokenPrice * credits
                              )
                            : 0}{" "}
                          BIDZ
                        </span>
                      </b>
                    </p>
                  </SlipageDiv>
                </div>
              </InputDiv>
              <SlipageDiv>
                <p>Credit Price:</p>
                <p>
                  <b>
                    {tokenPrice &&
                      CommonUtility.removeTrailingZeroes(tokenPrice)}{" "}
                    BIDZ
                  </b>
                </p>
              </SlipageDiv>
              <span style={{ color: "red" }}>
                {" "}
                {creditError && creditError}
              </span>
              {account ? (
                <SwapBtn onClick={() => handleDeposit()}>Swap Now</SwapBtn>
              ) : (
                <SwapBtn onClick={() => setIsModalOpen(true)}>
                  Connect Wallet
                </SwapBtn>
              )}
            </RightDiv>
          </Col>
        </Row>
      </Container>
      <Modal
        open={isModalOpen}
        centered
        onCancel={() => setIsModalOpen(false)}
        width={504}
        footer={false}
        closable={false}
      >
        <ConnectModal onCancel={() => setIsModalOpen(false)} />
      </Modal>

      <Modal
        open={isInfoModalOpen}
        centered
        onCancel={() => setIsInfoModalOpen(false)}
        width={551}
        footer={false}
        closable={false}
      >
        <InfoModal
          received={credits}
          spend={
            credits && !isNaN(tokenPrice) && !isNaN(credits)
              ? CommonUtility.removeTrailingZeroes(tokenPrice * credits)
              : 0
          }
          onCancel={() => {
            setIsInfoModalOpen(false);
            setCredits("");
          }}
        />
      </Modal>
    </HeroWrapper>
  );
};

export default HeroComp;
