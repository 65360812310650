import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
.cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  color: white;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif !important;
  } 
 body
{
  overflow-x: hidden !important;
  font-family: 'DM Sans', sans-serif !important;
   
}

.ant-modal .ant-modal-content {
    background-color: transparent ;
    box-shadow: none ;
}

`;
