import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgb(255, 220, 96, 0.7);
  padding: 1rem 2rem 2rem 2rem;
  min-height: 279px;
  position: relative;

  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }
`;

export const MainHeader = styled.h2`
  /* color: #3e3e3e; */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  margin-top: 2rem;
  text-align: center;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
`;

export const ConnectWalletBtn = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffdc60;
  border-radius: 5px;
  border: 1px solid #ffdc60;
  width: 100%;
  padding: 10px 2rem;

  @media (max-width: 450px) {
    padding: 10px 5px;
  }

  p {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;

    @media (max-width: 450px) {
      font-size: 16px;
    }
  }
`;
