import styled from "styled-components";

export const NavbarWrapper = styled.div`
  .nav-link {
    color: #8a90a2;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.625rem; /* 162.5% */
    text-transform: capitalize;
    margin-left: 2rem;
  }
`;

export const ConnectBtn = styled.button`
  color: #292930;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.75rem; /* 375% */
  text-transform: capitalize;
  border-radius: 0.625rem;
  background: #ffdc60;
  padding: 0rem 3.14938rem 0rem 3.16188rem;
  border: none;

  @media (max-width: 991.98px) {
    display: block;
    margin: 0 auto;
  }
`;
