import environment from "../../environment";

export const APIPath = {
  server: environment.BACKEND_BASE_URL,
  moralisServer: "https://nbnob0befkpo.usemoralis.com:2053/server",

  //admin
  login: "login",
  logout: "blacklist-token",
  auth: "auth",
  updatePassword: "update-password",
  updateUsername: "update-username",

  generateSignature: "generate-signature",
  getErc20Tx: "get-erc20-tx",


  totalSwapAmount: "total-swap-amount",
  totalSwaps: "total-swaps",

};
