import React, { useState } from "react";
import NavbarComp from "./navbar";
import HeroComp from "./heroSection";
import TableComp from "./tableComp";
import FooterComp from "./footer";
import { Erc20Service } from "../../service/erc20Service";

const HomeComp = () => {
  const [data, setData] = useState();

  const getTxs = async () => {
    try {
      let res = await Erc20Service.get();
      res = res?.data;
      setData(res?.reverse());
    } catch (error) {
      console.log("getTxs error", error);
    }
  };

  return (
    <div>
      <NavbarComp />
      <HeroComp getTxs={getTxs} />
      <TableComp getTxs={getTxs} data={data} />
      <FooterComp />
    </div>
  );
};

export default HomeComp;
