const environment = {
  //place API url here
  BACKEND_BASE_URL: "https://api.bidz.store:8060/",
  
  //addresses
  TOKEN_ADDRESS: {
    //testnet
    11155111: "0xC2EBAA5F640b30c0D6712A6E0656fB816C10a7d4",
    "eip155:11155111": "0xC2EBAA5F640b30c0D6712A6E0656fB816C10a7d4",

    //mainnet
    56: "0xC2EBAA5F640b30c0D6712A6E0656fB816C10a7d4",
    "eip155:56": "0xC2EBAA5F640b30c0D6712A6E0656fB816C10a7d4",
  },
  DEPOSIT_ADDRESS: {
    //testnet
    11155111: "0x91b5100BacE0c9caD10DDE87DB5c42Da497Ebe6c",
    "eip155:11155111": "0x91b5100BacE0c9caD10DDE87DB5c42Da497Ebe6c",

    //mainnet
    56: "0x91b5100BacE0c9caD10DDE87DB5c42Da497Ebe6c",
    "eip155:56": "0x91b5100BacE0c9caD10DDE87DB5c42Da497Ebe6c",
  },
  EXPLORER_URL: {
    //testnet
    11155111: "https://sepolia.etherscan.io/",
    "eip155:11155111": "https://sepolia.etherscan.io/",
    //mainnet
    56: "https://bscscan.com/",
    "eip155:56": "https://bscscan.com/",

  },
  DEFAULT_CHAIN: 11155111,
  COIN_GECKO:
    "https://api.coingecko.com/api/v3/simple/price?ids=bidz-coin&vs_currencies=usd",
};
export default environment;
