import { APIPath } from "../utility/constant";
import { BaseService } from "./baseService";

class Erc20 {
    get = () => {
        return BaseService.get(`${APIPath.getErc20Tx}`);
    };

    totalSwapAmount = () => {
        return BaseService.get(`${APIPath.totalSwapAmount}`);
    };
    totalSwaps = () => {
        return BaseService.get(`${APIPath.totalSwaps}`);
    };


}

const Erc20Service = new Erc20();
Object.freeze(Erc20Service);
export { Erc20Service };
