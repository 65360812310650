import React from "react";
import { Info, ModalWrapper } from "./elements";
import { MdClose } from "react-icons/md";

const InfoModal = ({ spend, onCancel, received }) => {
  return (
    <ModalWrapper>
      <MdClose
        cursor="pointer"
        fontSize={22}
        color="#6B6B6B"
        className="close-icon"
        onClick={onCancel}
      />

      <Info>
        <h5>Credits received:</h5>
        <p>{received || 0}</p>
      </Info>
      <Info>
        <h5>Tokens spend:</h5>
        <p>{spend} BIDZ</p>
      </Info>
    </ModalWrapper>
  );
};

export default InfoModal;
