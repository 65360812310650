import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import {
  ButtonsDiv,
  ConnectWalletBtn,
  MainHeader,
  ModalWrapper,
} from "./elements";
import { MdClose } from "react-icons/md";
import { walletconnect, metamask } from "../../../assets";
import { useWalletConnectClient } from "../../../clientContext";

const ConnectModal = ({ onCancel }) => {
  const { account, connect, connectWithInjected } = useWalletConnectClient();

  useEffect(() => {
    if (account) {
      onCancel();
    }
  }, [account]);
  return (
    <div>
      <ModalWrapper>
        <MdClose
          cursor="pointer"
          fontSize={22}
          color="#6B6B6B"
          className="close-icon"
          onClick={onCancel}
        />
        <MainHeader>Connect A Wallet</MainHeader>

        <ButtonsDiv>
          <ConnectWalletBtn
            onClick={() => {
              connectWithInjected("11155111");
            }}
          >
            <p>Metamask</p>
            <Image src={metamask} />
          </ConnectWalletBtn>

          <ConnectWalletBtn
            onClick={() => {
              connect("eip155:11155111");
              onCancel();
            }}
          >
            <p>WalletConnect</p>
            <Image src={walletconnect} />
          </ConnectWalletBtn>
        </ButtonsDiv>
      </ModalWrapper>
    </div>
  );
};

export default ConnectModal;
