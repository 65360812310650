import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgb(255, 220, 96, 0.7);
  padding: 1rem 2rem 2rem 2rem;
  min-height: 15.875rem;
  position: relative;

  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  h5 {
    color: #292930;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.65rem; /* 120% */
    margin: 0;
  }
  p {
    color: #8a90a2;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.625rem; /* 162.5% */
    margin: 0;
  }
`;
