import styled from "styled-components";

export const TableCompWrapper = styled.div`
  .container {
    margin-top: 8rem;
  }
`;

export const TableDiv = styled.div`
  .ant-table-wrapper .ant-table {
    @media (max-width: 991.98px) {
      overflow-x: auto !important;
    }
  }
  :where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper
    .ant-table-thead
    > tr
    > th,
  :where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper
    .ant-table-thead
    > tr
    > td {
    color: #212529;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 185.714% */
    text-align: center;
  }

  :where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper
    .ant-table-tbody
    > tr
    > th,
  :where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper
    .ant-table-tbody
    > tr
    > td {
    border-bottom: none;
    text-align: center;
    color: #212529;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
    padding: 30px 0;
    background: #f2f6ff;
  }

  :where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper
    .ant-table-thead
    > tr
    > th,
  :where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper
    .ant-table-thead
    > tr
    > td {
    background: #f2f6ff;
    border-bottom: none;
  }

  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > th,
  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-wrapper .ant-table-tbody > tr > th.ant-table-cell-row-hover,
  .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: #f2f6ff !important;
  }

  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: #f2f6ff !important;
  }

  .ant-table-body {
    &::-webkit-scrollbar {
      width: 15px;
    }

    &::-webkit-scrollbar-track {
      border: 1px solid rgb(217, 217, 217);
      background: #e1e1e1;
    }

    &::-webkit-scrollbar-thumb {
      background: #a8a6fa;
    }
  }
`;
